import React, { useEffect, useRef, useState } from "react";
import "./App.scss";
import { Avatar, Button, Form, Input } from "antd";
import avtchatbot from "./assets/img/avtchatbot.png";
import logo from "./assets/img/logo.png";
import logobig from "./assets/img/logobig.png";
import {
  HistoryOutlined,
  CloseOutlined,
  PictureOutlined,
  SendOutlined,
} from "@ant-design/icons";
import Chat from "./apis/Message.api";

function App() {
  // for testing
  console.log(process.env.REACT_APP_API_URL);
  
  const [form] = Form.useForm();
  const [data, setData] = useState<any[]>([
    { id: "AI", message: "Xin chào, tôi có thể giúp gì cho bạn?" },
  ]);
  const [chatId, setChatId] = useState<string>("");
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const ref = useRef<HTMLInputElement>(null);

  useEffect(() => {
    if (ref.current) {
      ref.current.scrollIntoView(true);
    }
  }, [data]);

  const urlify = (text: string) => {
    var urlRegex = /(https?:\/\/[^\s]+)/g;
    return text.replace(urlRegex, function (url) {
      return '<a href="' + url + '" target="_blank">' + url + "</a>";
    });
  };

  const getResponse = async (message: string) => {
    setIsLoading(true);

    const response = await Chat.chat(chatId, message);

    setIsLoading(false);
    setData((prev: any) => {
      return [...prev, { id: "AI", message: urlify(response.answer) }];
    });
    setChatId(response.chat_id);
  };

  const onFinish = (values: any) => {
    if (values.message) {
      setData((prev: any) => {
        return [...prev, { id: "user", ...values }];
      });

      getResponse(values.message);
      form.resetFields();
    }
  };

  return (
    <div className="App">
      <div className="container">
        <div className="header">
          <div className="header-body">
            <div className="flex">
              <Avatar size={48} src={avtchatbot} />
              <div>
                <div className="title">Support ChatBot</div>
                <div className="status">Online</div>
              </div>
            </div>

            <div className="flex">
              <Button type="link" block size="large">
                <HistoryOutlined />
              </Button>
              <Button type="link" block size="large">
                <CloseOutlined />
              </Button>
            </div>
          </div>
        </div>
        <div className="body">
          <div className="box">
            {data.map((item, index) => {
              if (item.id === "user") {
                return (
                  <div key={index} className="user-message">
                    {item.message}
                  </div>
                );
              } else {
                return (
                  <div key={index} className="flex-bottom">
                    <Avatar size={24} src={logo} />
                    <div
                      className="AI-message"
                      dangerouslySetInnerHTML={{ __html: item.message }}
                    />
                  </div>
                );
              }
            })}
            <div hidden={!isLoading} className="loader">
              Support ChatBot is typing...
            </div>
            <div style={{ float: "left", clear: "both" }} ref={ref}></div>
          </div>
        </div>
        <div className="footer">
          <Form form={form} onFinish={onFinish} className="footer-body">
            <Button shape="circle" icon={<PictureOutlined />} size="large" />
            <Form.Item noStyle name="message">
              <Input autoFocus placeholder="Type your message here" />
            </Form.Item>
            <Button
              htmlType="submit"
              shape="circle"
              icon={<SendOutlined />}
              size="large"
            />
          </Form>
        </div>
      </div>
    </div>
  );
}

export default App;
