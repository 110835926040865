import axiosClient from "../config/axios";

const url = "http://localhost:8000/api/v1/chat/";

const chat = (chat_id: string, message: string) => {
  return axiosClient
    .post(url, {
      chat_id,
      message,
    })
    .then((response: any) => {
      return response;
    });
};

const Chat = { chat };

export default Chat;
